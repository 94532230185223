import { Attribute, OrderItem as LineItemModel } from "../models";
import * as React from "react";
import Api from "../api";
import { AxiosProgressEvent } from "axios";
import { Button, Card, Tabs, Tab, Form } from "react-bootstrap";

import EditableImage from "./EditableImage";

interface Props {
    item: LineItemModel;
    idx: number;
}

const basename = (url: string) => {
    return url.split("/").pop();
};
interface State {
    item: LineItemModel;
    imgUploading: boolean;
    imgUploadingErrorFiles: string[];
    uploadProgress: number;
    markArrivedLoading: boolean;
    markArrivedHide: boolean;
    markDamagedLoading: boolean;
    markDamagedHide: boolean;
    markQcLoading: boolean;
    markQcHide: boolean;
    markLoanerReturnLoading: boolean;
    markLoanerReturnHide: boolean;
    markKitSentLoading: boolean;
    markKitSentHide: boolean;
    artReceivedDateString: string;
    artDamagedDateString: string;
    artQcDateString: string;
    loanerReturnDateString: string;
    kitSentDateString: string;
    damageStatus: string;
    damageStatusError: boolean;
}

const attrVal = (attributes: Attribute[], key: string) => {
    const found = attributes.find(a => a.key === key);
    return found ? found.value : null;
};

export default class LineItem extends React.Component<Props, State> {
    // private fileInputRef : React.RefObject<HTMLInputElement>;

    private fileInputRef = React.createRef<HTMLInputElement>();
    private fileDamagedInputRef = React.createRef<HTMLInputElement>();
    private fileQcInputRef = React.createRef<HTMLInputElement>();
    private fileLoanerReturnInputRef = React.createRef<HTMLInputElement>();
    private fileKitSentInputRef = React.createRef<HTMLInputElement>();
    state: State;

    constructor(props: Props) {
        super(props);
        this.state = {
            item: props.item,
            imgUploading: false,
            imgUploadingErrorFiles: [],
            markArrivedLoading: false,
            markArrivedHide: false,
            markDamagedLoading: false,
            markDamagedHide: false,
            markQcLoading: false,
            markQcHide: false,
            markLoanerReturnLoading: false,
            markLoanerReturnHide: false,
            markKitSentLoading: false,
            markKitSentHide: false,
            uploadProgress: 0,
            artReceivedDateString: "",
            artDamagedDateString: "",
            artQcDateString: "",
            loanerReturnDateString: "",
            kitSentDateString: "",
            damageStatus: "",
            damageStatusError: false
        };
    }

    componentDidMount(): void {
        let artReceivedAt = attrVal(this.state.item.order.meta, "knives_received_at_" + this.state.item.id);
        if (!artReceivedAt) {
            artReceivedAt = attrVal(this.state.item.order.meta, "knives_received_at");
        }
        if (artReceivedAt) {
            const receive: Record<string, string> = JSON.parse(artReceivedAt);
            const artReceived = Object.values(receive)[0].toString();
            const artReceivedDate = new Date(artReceived);
            this.setState({ artReceivedDateString: artReceivedDate.toString() });
        }

        let artDamagedAt = attrVal(this.state.item.order.meta, "knives_damaged_at_" + this.state.item.id);
        if (!artDamagedAt) {
            artDamagedAt = attrVal(this.state.item.order.meta, "knives_damaged_at");
        }
        if (artDamagedAt) {
            const damage: Record<string, string> = JSON.parse(artDamagedAt);
            const artDamaged = Object.values(damage)[0].toString();
            const artDamagedDate = new Date(artDamaged);
            this.setState({ artDamagedDateString: artDamagedDate.toString() });
        }

        let artQcAt = attrVal(this.state.item.order.meta, "knives_qc_at_" + this.state.item.id);
        if (!artQcAt) {
            artQcAt = attrVal(this.state.item.order.meta, "knives_qc_at");
        }
        if (artQcAt) {
            const qc: Record<string, string> = JSON.parse(artQcAt);
            const artQc = Object.values(qc)[0].toString();
            const artQcDate = new Date(artQc);
            this.setState({ artQcDateString: artQcDate.toString() });
        }

        let loanerReturnAt = attrVal(this.state.item.order.meta, "loaner_returned_at_" + this.state.item.id);
        if (!loanerReturnAt) {
            loanerReturnAt = attrVal(this.state.item.order.meta, "loaner_returned_at");
        }
        if (loanerReturnAt) {
            const loanerReturn: Record<string, string> = JSON.parse(loanerReturnAt);
            const loanerReturnObj = Object.values(loanerReturn)[0].toString();
            const loanerReturnDate = new Date(loanerReturnObj);
            this.setState({ loanerReturnDateString: loanerReturnDate.toString() });
        }

        let kitSentAt = attrVal(this.state.item.order.meta, "kit_sent_at_" + this.state.item.id);
        if (!kitSentAt) {
            kitSentAt = attrVal(this.state.item.order.meta, "kit_sent_at");
        }
        if (kitSentAt) {
            const kitSent: Record<string, string> = JSON.parse(kitSentAt);
            const kentSentObj = Object.values(kitSent)[0].toString();
            const kitSentDate = new Date(kentSentObj);
            this.setState({ kitSentDateString: kitSentDate.toString() });
        }

        let damageStatus = attrVal(this.state.item.order.meta, "damage_status_" + this.state.item.id);
        if(!damageStatus) {
            damageStatus = attrVal(this.state.item.order.meta, "damage_status");
        }
        if (damageStatus) {
            const damageValue: Record<string, string> = JSON.parse(damageStatus);
            const damageState = Object.values(damageValue)[0].toString();
            this.setState({ damageStatus: damageState });
        }
    }

    render(): JSX.Element {
        const itm = this.state.item;
        const productName = itm.name;
        const sku = itm.sku 
        const note = itm.order.note;
        const itemPreviewImage = null;

        // const productName = attrVal(itm.attributes, '_Product Name');
        return (
            <Card className="line-item">
                <Card.Header>
                    {itm.order.name}-{itm.lineNum} - {itm.order.customerName} {" - "}
                    <strong>{itm.order.customerEmail}</strong>
                </Card.Header>
                <Card.Body>
                    <Tabs
                        defaultActiveKey="preview"
                        onSelect={() => this.setState({ imgUploadingErrorFiles: [] })}
                        id="photo-nav"
                        className="mb-3"
                        style={{ borderBottom: "1px solid #dee2e6" }}
                    >
                        <Tab eventKey="preview" title="Preview">
                            {itemPreviewImage && (
                                <div className="images">
                                    <img src={itemPreviewImage} key={itemPreviewImage} alt="Order Preview" />
                                </div>
                            )}
                            {productName && <Card.Text>Product Name: {productName}</Card.Text>}
                            {sku && <Card.Text>SKU: {sku}</Card.Text>}
                            {note && <Card.Text>Note: {note}</Card.Text>}
                        </Tab>
                        <Tab eventKey="kit-sent" title="Kit Sent">
                            <div className="images">
                                {itm.kitSentImages.map(img => (
                                    <EditableImage
                                        src={img.url}
                                        key={img.url}
                                        onImageChanged={() => this.saveItemImage(img.url, "kit-sent")}
                                        onImageDelete={() => this.deleteItemImage(img.url, "kit-sent")}
                                    />
                                ))}
                            </div>
                            <div style={{ textAlign: "left" }}>
                                <label htmlFor={"ipt" + itm.id}>
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={this.handleKitSentPhotoButtonClick}
                                        disabled={this.state.imgUploading}
                                    >
                                        {this.state.imgUploading
                                            ? `Uploading: ${this.state.uploadProgress}%`
                                            : "+ Kit Sent Photo"}
                                    </Button>
                                </label>
                                <input
                                    name="kit-sent"
                                    type="file"
                                    accept="image/*,capture=camera"
                                    multiple={true}
                                    onChange={this.handleFileInputChange}
                                    ref={this.fileKitSentInputRef}
                                />
                                {this.state.markKitSentHide ? (
                                    <>
                                        <div style={{ float: "right" }}>Mark as Sent?</div>
                                        <Button
                                            variant="outline-success"
                                            size="sm"
                                            disabled={this.state.markKitSentLoading}
                                            className="ml-2 pull-right"
                                            onClick={this.handleMarkKitSent}
                                            style={{ float: "right" }}
                                        >
                                            Yes
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="outline-success"
                                        size="sm"
                                        disabled={this.state.markKitSentLoading}
                                        className="ml-2 pull-right"
                                        onClick={() => this.hideHandleMarkKitSent(true)}
                                        style={{ float: "right" }}
                                    >
                                        Kit Sent
                                    </Button>
                                )}
                                {this.state.imgUploadingErrorFiles.length > 0 && (
                                    <Card.Text className="error">
                                        Please try again. Failed to upload:
                                        {this.state.imgUploadingErrorFiles.map((filename) => (
                                          <span className="error" key={filename}>{filename}</span>
                                        ))}
                                    </Card.Text>
                                )}
                                {this.state.kitSentDateString && (
                                    <Card.Text style={{ float: "right" }}>
                                        Previous Kit Sent: {this.state.kitSentDateString}
                                    </Card.Text>
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="damage" title="Damage">
                            {itm.damagedImages && (
                                <div className="images">
                                    {itm.damagedImages.map(img => (
                                        <EditableImage
                                            src={img.url}
                                            key={img.url}
                                            onImageChanged={() => this.saveItemImage(img.url, "damage")}
                                            onImageDelete={() => this.deleteItemImage(img.url, "damage")}
                                        />
                                    ))}
                                </div>
                            )}
                            <div>
                                <div style={{ textAlign: "left" }}>
                                    <label htmlFor={"ipt" + itm.id}>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={this.handleDamagedPhotoButtonClick}
                                            disabled={this.state.imgUploading}
                                        >
                                            {this.state.imgUploading
                                                ? `Uploading: ${this.state.uploadProgress}%`
                                                : "+ Damage Photo"}
                                        </Button>
                                    </label>
                                    <input
                                        name="damage"
                                        type="file"
                                        accept="image/*,capture=camera"
                                        multiple={true}
                                        onChange={this.handleFileInputChange}
                                        ref={this.fileDamagedInputRef}
                                    />
                                    <div style={{ float: "right" }}>
                                        {this.state.damageStatusError ? (
                                            <label style={{ color: "red" }}>Damage Status Must Be Selected</label>
                                        ) : (
                                            <label>Select Damage Status</label>
                                        )}

                                        <Form>
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="tip-broken"
                                                checked={this.state.damageStatus === "tip-broken" ? true : false}
                                                label="Tip broken - Proceed"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="chipped-blade"
                                                checked={this.state.damageStatus === "chipped-blade" ? true : false}
                                                label="Chipped Blade - Proceed"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="micro-serrated"
                                                checked={this.state.damageStatus === "micro-serrated" ? true : false}
                                                label="Micro Serrated - HOLD"
                                                onChange={this.handleDamageStatus}
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="damage-status-radio"
                                                value="broken-handle"
                                                checked={this.state.damageStatus === "broken-handle" ? true : false}
                                                label="Cracked / Broken Handle - HOLD"
                                                onChange={this.handleDamageStatus}
                                            />
                                        </Form>

                                        {this.state.markDamagedHide ? (
                                            <>
                                                <div style={{ float: "right" }}>Mark as damaged?</div>
                                                <Button
                                                    variant="outline-success"
                                                    size="sm"
                                                    disabled={this.state.markDamagedLoading}
                                                    className="ml-2 pull-right"
                                                    onClick={this.handleMarkDamaged}
                                                    style={{ float: "right" }}
                                                >
                                                    Yes
                                                </Button>
                                            </>
                                        ) : (
                                            <Button
                                                variant="outline-success"
                                                size="sm"
                                                disabled={this.state.markDamagedLoading}
                                                className="ml-2 pull-right"
                                                onClick={() => this.hideHandleMarkDamaged(true)}
                                                style={{ float: "right" }}
                                            >
                                                Damaged
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                {this.state.imgUploadingErrorFiles.length > 0 && (
                                    <Card.Text className="error">
                                        Please try again. Failed to upload:
                                        {this.state.imgUploadingErrorFiles.map((filename) => (
                                          <span className="error" key={filename}>{filename}</span>
                                        ))}
                                    </Card.Text>
                                )}
                                {this.state.artDamagedDateString && (
                                    <Card.Text style={{ float: "right" }}>
                                        Previous Damage: {this.state.artDamagedDateString}
                                    </Card.Text>
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="checkin" title="Checkin">
                            <div className="images">
                                {itm.images.map(img => (
                                    <EditableImage
                                        src={img.url}
                                        key={img.url}
                                        onImageChanged={() => this.saveItemImage(img.url, "checkin")}
                                        onImageDelete={() => this.deleteItemImage(img.url, "checkin")}
                                    />
                                ))}
                            </div>
                            <div>
                                <div style={{ textAlign: "left" }}>
                                    <label htmlFor={"ipt" + itm.id}>
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={this.handlePhotoButtonClick}
                                            disabled={this.state.imgUploading}
                                        >
                                            {this.state.imgUploading
                                                ? `Uploading: ${this.state.uploadProgress}%`
                                                : "+ Checkin Photo"}
                                        </Button>
                                    </label>
                                    <input
                                        name="checkin"
                                        type="file"
                                        accept="image/*,capture=camera"
                                        multiple={true}
                                        onChange={this.handleFileInputChange}
                                        ref={this.fileInputRef}
                                    />

                                    {this.state.markArrivedHide ? (
                                        <>
                                            <div style={{ float: "right" }}>Mark as checked in?</div>
                                            <Button
                                                variant="outline-success"
                                                size="sm"
                                                disabled={this.state.markArrivedLoading}
                                                className="ml-2 pull-right"
                                                onClick={this.handleMarkArrived}
                                                style={{ float: "right" }}
                                            >
                                                Yes
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            variant="outline-success"
                                            size="sm"
                                            disabled={this.state.markArrivedLoading}
                                            className="ml-2 pull-right"
                                            onClick={() => this.hideHandleMarkArrived(true)}
                                            style={{ float: "right" }}
                                        >
                                            Check In
                                        </Button>
                                    )}
                                </div>
                                {this.state.imgUploadingErrorFiles.length > 0 && (
                                    <Card.Text className="error">
                                        Please try again. Failed to upload:
                                        {this.state.imgUploadingErrorFiles.map((filename) => (
                                          <span className="error" key={filename}>{filename}</span>
                                        ))}
                                    </Card.Text>
                                )}
                                {this.state.artReceivedDateString && (
                                    <Card.Text style={{ float: "right" }}>
                                        Previous CheckIn: {this.state.artReceivedDateString}
                                    </Card.Text>
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="qc" title="QC">
                            <div className="images">
                                {itm.qcImages.map(img => (
                                    <EditableImage
                                        src={img.url}
                                        key={img.url}
                                        onImageChanged={() => this.saveItemImage(img.url, "qc")}
                                        onImageDelete={() => this.deleteItemImage(img.url, "qc")}
                                    />
                                ))}
                            </div>
                            <div style={{ textAlign: "left" }}>
                                <label htmlFor={"ipt" + itm.id}>
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={this.handleQcPhotoButtonClick}
                                        disabled={this.state.imgUploading}
                                    >
                                        {this.state.imgUploading
                                            ? `Uploading: ${this.state.uploadProgress}%`
                                            : "+ QC Photo"}
                                    </Button>
                                </label>
                                <input
                                    name="qc"
                                    type="file"
                                    accept="image/*,capture=camera"
                                    multiple={true}
                                    onChange={this.handleFileInputChange}
                                    ref={this.fileQcInputRef}
                                />
                                {this.state.markQcHide ? (
                                    <>
                                        <div style={{ float: "right" }}>Mark as complete?</div>
                                        <Button
                                            variant="outline-success"
                                            size="sm"
                                            disabled={this.state.markQcLoading}
                                            className="ml-2 pull-right"
                                            onClick={this.handleMarkQc}
                                            style={{ float: "right" }}
                                        >
                                            Yes
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="outline-success"
                                        size="sm"
                                        disabled={this.state.markQcLoading}
                                        className="ml-2 pull-right"
                                        onClick={() => this.hideHandleMarkQc(true)}
                                        style={{ float: "right" }}
                                    >
                                        Complete
                                    </Button>
                                )}
                                {this.state.imgUploadingErrorFiles.length > 0 && (
                                    <Card.Text className="error">
                                        Please try again. Failed to upload:
                                        {this.state.imgUploadingErrorFiles.map((filename) => (
                                          <span className="error" key={filename}>{filename}</span>
                                        ))}
                                    </Card.Text>
                                )}
                                {this.state.artQcDateString && (
                                    <Card.Text style={{ float: "right" }}>
                                        Previous QC: {this.state.artQcDateString}
                                    </Card.Text>
                                )}
                            </div>
                        </Tab>
                        <Tab eventKey="loaner-return" title="Loaner Return">
                            <div className="images">
                                {itm.loanerReturnedImages.map(img => (
                                    <EditableImage
                                        src={img.url}
                                        key={img.url}
                                        onImageChanged={() => this.saveItemImage(img.url, "loaner-return")}
                                        onImageDelete={() => this.deleteItemImage(img.url, "loaner-return")}
                                    />
                                ))}
                            </div>
                            <div style={{ textAlign: "left" }}>
                                <label htmlFor={"ipt" + itm.id}>
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        onClick={this.handleLoanerReturnPhotoButtonClick}
                                        disabled={this.state.imgUploading}
                                    >
                                        {this.state.imgUploading
                                            ? `Uploading: ${this.state.uploadProgress}%`
                                            : "+ Loaner Return Photo"}
                                    </Button>
                                </label>
                                <input
                                    name="loaner-return"
                                    type="file"
                                    accept="image/*,capture=camera"
                                    multiple={true}
                                    onChange={this.handleFileInputChange}
                                    ref={this.fileLoanerReturnInputRef}
                                />
                                {this.state.markLoanerReturnHide ? (
                                    <>
                                        <div style={{ float: "right" }}>Mark as Returned?</div>
                                        <Button
                                            variant="outline-success"
                                            size="sm"
                                            disabled={this.state.markLoanerReturnLoading}
                                            className="ml-2 pull-right"
                                            onClick={this.handleMarkLoanerReturn}
                                            style={{ float: "right" }}
                                        >
                                            Yes
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="outline-success"
                                        size="sm"
                                        disabled={this.state.markLoanerReturnLoading}
                                        className="ml-2 pull-right"
                                        onClick={() => this.hideHandleMarkLoanerReturn(true)}
                                        style={{ float: "right" }}
                                    >
                                        Returned
                                    </Button>
                                )}
                                {this.state.imgUploadingErrorFiles.length > 0 && (
                                    <Card.Text className="error">
                                        Please try again. Failed to upload:
                                        {this.state.imgUploadingErrorFiles.map((filename) => (
                                          <span className="error" key={filename}>{filename}</span>
                                        ))}
                                    </Card.Text>
                                )}
                                {this.state.loanerReturnDateString && (
                                    <Card.Text style={{ float: "right" }}>
                                        Previous Loaner Returned: {this.state.loanerReturnDateString}
                                    </Card.Text>
                                )}
                            </div>
                        </Tab>
                    </Tabs>
                </Card.Body>
            </Card>
        );
    }

    private hideHandleMarkArrived = (state: boolean) => {
        this.setState({ markArrivedHide: state });
        setTimeout(() => {
            // reset confirmation state if no confirmation comes in a reasonable window.
            !this.state.markArrivedLoading && this.setState({ markArrivedHide: false });
        }, 4000);
    };

    private handleMarkArrived = async () => {
        this.setState({
            markArrivedLoading: true
        });
        try {
            await Api.markArtReceived(this.state.item);
            this.setState({ markArrivedHide: false });
            this.setState({
                markArrivedLoading: false
            });
            this.setState({ artReceivedDateString: new Date().toString() });
        } catch (e) {
            console.error(`Something went wrong ${e}`);
            this.setState({
                markArrivedLoading: false
            });
        }
    };

    private handleDamageStatus = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value) {
            this.setState({ damageStatusError: false });
            this.setState({ damageStatus: event.target.value });
        }
    };

    private hideHandleMarkDamaged = (state: boolean) => {
        if (this.state.damageStatus) {
            this.setState({ markDamagedHide: state });
            setTimeout(() => {
                // reset confirmation state if no confirmation comes in a reasonable window.
                !this.state.markDamagedLoading && this.setState({ markDamagedHide: false });
            }, 4000);
        } else {
            this.setState({ damageStatusError: true });
        }
    };

    private handleMarkDamaged = async () => {
        this.setState({ markDamagedLoading: true });
        try {
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.item.damageStatus = this.state.damageStatus;
            await Api.markArtDamaged(this.state.item);
            this.setState({ markDamagedHide: false });
            this.setState({ markDamagedLoading: false });
            this.setState({ artDamagedDateString: new Date().toString() });
        } catch (e) {
            console.error(`Something went wrong ${e}`);
            this.setState({
                markDamagedLoading: false
            });
        }
    };

    private hideHandleMarkQc = (state: boolean) => {
        this.setState({ markQcHide: state });
        setTimeout(() => {
            // reset confirmation state if no confirmation comes in a reasonable window.
            !this.state.markQcLoading && this.setState({ markQcHide: false });
        }, 4000);
    };

    private handleMarkQc = async () => {
        this.setState({
            markQcLoading: true
        });
        try {
            await Api.markArtQc(this.state.item);
            this.setState({ markQcHide: false });
            this.setState({ markQcLoading: false });
            this.setState({ artQcDateString: new Date().toString() });
        } catch (e) {
            console.error(`Something went wrong ${e}`);
            this.setState({
                markQcLoading: false
            });
        }
    };

    private hideHandleMarkLoanerReturn = (state: boolean) => {
        this.setState({ markLoanerReturnHide: state });
        setTimeout(() => {
            // reset confirmation state if no confirmation comes in a reasonable window.
            !this.state.markLoanerReturnLoading && this.setState({ markLoanerReturnHide: false });
        }, 4000);
    };

    private handleMarkLoanerReturn = async () => {
        this.setState({
            markLoanerReturnLoading: true
        });
        try {
            await Api.markLoanerReturn(this.state.item);
            this.setState({ markLoanerReturnHide: false });
            this.setState({ markLoanerReturnLoading: false });
            this.setState({ loanerReturnDateString: new Date().toString() });
        } catch (e) {
            console.error(`Something went wrong ${e}`);
            this.setState({
                markLoanerReturnLoading: false
            });
        }
    };

    private hideHandleMarkKitSent = (state: boolean) => {
        this.setState({ markKitSentHide: state });
        setTimeout(() => {
            // reset confirmation state if no confirmation comes in a reasonable window.
            !this.state.markKitSentLoading && this.setState({ markKitSentHide: false });
        }, 4000);
    };

    private handleMarkKitSent = async () => {
        this.setState({
            markKitSentLoading: true
        });
        try {
            await Api.markKitSent(this.state.item);
            this.setState({ markKitSentHide: false });
            this.setState({ markKitSentLoading: false });
            this.setState({ kitSentDateString: new Date().toString() });
        } catch (e) {
            console.error(`Something went wrong ${e}`);
            this.setState({
                markKitSentLoading: false
            });
        }
    };

    private handlePhotoButtonClick = () => {
        const fileInput = this.fileInputRef.current;
        if (fileInput) {
            fileInput.click();
        }
    };

    private handleDamagedPhotoButtonClick = () => {
        const fileInput = this.fileDamagedInputRef.current;
        if (fileInput) {
            fileInput.click();
        }
    };

    private handleQcPhotoButtonClick = () => {
        const fileInput = this.fileQcInputRef.current;
        if (fileInput) {
            fileInput.click();
        }
    };

    private handleLoanerReturnPhotoButtonClick = () => {
        const fileInput = this.fileLoanerReturnInputRef.current;
        if (fileInput) {
            fileInput.click();
        }
    };

    private handleKitSentPhotoButtonClick = () => {
        const fileInput = this.fileKitSentInputRef.current;
        if (fileInput) {
            fileInput.click();
        }
    };

    // This method could be crammed down into EditableImage, but we pass
    // a handler down so EditableImage doesn't need to know about Api.
    private saveItemImage = async (newUrl: string, type: string) => {
        await Api.saveImagesForItem(this.state.item, [newUrl], type);
    };

    private deleteItemImage = async (image: string, type: string) => {
        try {
            await Api.deleteImageForItem(this.state.item, { url: image }, type);

            this.setState(state => {
                const {
                    item,
                    item: { images, damagedImages, qcImages, loanerReturnedImages, kitSentImages}
                } = state;
                let index: number;
                switch (type) {
                    case "checkin": {
                        index = images.findIndex(i => basename(i.url) === basename(image));
                        if (index > -1) {
                            images.splice(index, 1);
                        } else {
                            throw new Error(`Unable to delete ${image}`);
                        }
                        item.images = images;
                        break;
                    }
                    case "damage": {
                        index = damagedImages.findIndex(i => basename(i.url) === basename(image));
                        if (index > -1) {
                            damagedImages.splice(index, 1);
                        } else {
                            throw new Error(`Unable to delete ${image}`);
                        }
                        item.damagedImages = damagedImages;
                        break;
                    }
                    case "qc": {
                        index = qcImages.findIndex(i => basename(i.url) === basename(image));
                        if (index > -1) {
                            qcImages.splice(index, 1);
                        } else {
                            throw new Error(`Unable to delete ${image}`);
                        }
                        item.qcImages = qcImages;
                        break;
                    }
                    case "loaner-return": {
                        index = loanerReturnedImages.findIndex(i => basename(i.url) === basename(image));
                        if (index > -1) {
                            loanerReturnedImages.splice(index, 1);
                        } else {
                            throw new Error(`Unable to delete ${image}`);
                        }
                        item.loanerReturnedImages = loanerReturnedImages;
                        break;
                    }
                    case "kit-sent": {
                        index = kitSentImages.findIndex(i => basename(i.url) === basename(image));
                        if (index > -1) {
                            kitSentImages.splice(index, 1);
                        } else {
                            throw new Error(`Unable to delete ${image}`);
                        }
                        item.kitSentImages = kitSentImages;
                        break;
                    }
                    default: {
                        break;
                    }
                }

                return {
                    ...state,
                    item
                };
            });
        } catch (e) {
            console.log(`Unable to delete ${image}: ${e}`);
        }
    };

    private handleFileInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        const type = e.target.name;
        if (!files || !files.length) return;

        this.setState({ imgUploading: true, imgUploadingErrorFiles: [], uploadProgress: 0 });

        let filesTotalSize = 0;
        let filesLoadedSize = 0;
        const onProgress = (e: AxiosProgressEvent) => {
            filesLoadedSize += e.bytes;
            this.setState({
                uploadProgress: Math.round((filesLoadedSize * 100) / filesTotalSize)
            });
        };

        const promises: Promise<string>[] = [];
        for (const file of files) {
            filesTotalSize += file.size;
            promises.push(new Promise(async (resolve, reject) => {
                try {
                    const newUrl = await Api.putImage(file, file.name, onProgress);
                    resolve(newUrl);
                } catch (e) {
                    reject(file.name)
                }
            }));
        }
        const results = await Promise.allSettled(promises)
        const [resolvedUrls, rejectedFiles] = results.reduce<string[][]>((acc, r) => {
            if (r.status === 'fulfilled') {
                acc[0].push(r.value);
            } else {
                acc[1].push(r.reason);
            }
            return acc;

        }, [[],[]]);
        if (resolvedUrls.length) {
            try {
                await Api.saveImagesForItem(this.state.item, resolvedUrls, type);
            } catch (e) {
                console.log('saveImagesForItem e:', e);
                // Save failed, so move all resolvedUrls to rejectedFiles
                while (resolvedUrls.length) {
                    const failedUrl = resolvedUrls.shift();
                    rejectedFiles.push(decodeURIComponent(basename((failedUrl as string)) as string));
                }
            }
            for (const newUrl of resolvedUrls) {
                switch (type) {
                    case "checkin": {
                        this.state.item.images.push({ url: newUrl });
                        break;
                    }
                    case "damage": {
                        if (this.state.item.damagedImages) {
                            this.state.item.damagedImages.push({ url: newUrl });
                        }
                        break;
                    }
                    case "qc": {
                        if (this.state.item.qcImages) {
                            this.state.item.qcImages.push({ url: newUrl });
                        }
                        break;
                    }
                    case "loaner-return": {
                        if (this.state.item.loanerReturnedImages) {
                            this.state.item.loanerReturnedImages.push({ url: newUrl });
                        }
                        break;
                    }
                    case "kit-sent": {
                        if (this.state.item.kitSentImages) {
                            this.state.item.kitSentImages.push({ url: newUrl });
                        }
                        break;
                    }
                    default: {
                        break;
                    }
                }
            }
        }
        this.setState({
            item: this.state.item,
            imgUploading: false,
            imgUploadingErrorFiles: rejectedFiles,
        });
    };
}
